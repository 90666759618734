import React, { useEffect, useState } from 'react';
import './introduction.css';
import './introduction-media.css';

// components
import Wrapper from '../Layout/Wrapper/Wrapper';

// utils
import IntroImg from '../../assets/intro_img.jpg';

const Introduction = () => {
  const [className, setClassName] = useState({
    headerText: '',
    aboutText: '',
    visionText: ''
  });

  const initializeSizeCheck = (innerWidth) => {
    switch (true) {
      case innerWidth <= 959:
        return '82rem';
      default:
        return '60rem';
    }
  };

  const [contentSize, setContentSize] = useState(initializeSizeCheck(window.innerWidth));

  useEffect(() => {

    const handleResize = () => {
      const size = initializeSizeCheck(window.innerWidth);
      setContentSize(size);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const headerTimer = setTimeout(() => {
      setClassName((prevState) => {
        return {
          ...prevState,
          headerText: 'intro-fade'
        };
      })
    }, 100);

    const aboutTimer = setTimeout(() => {
      setClassName((prevState) => {
        return {
          ...prevState,
          aboutText: 'intro-fade'
        };
      })
    }, 750);

    const visionTimer = setTimeout(() => {
      setClassName((prevState) => {
        return {
          ...prevState,
          visionText: 'intro-fade'
        };
      })
    }, 1450);

    return () => {
      clearTimeout(headerTimer);
      clearTimeout(aboutTimer);
      clearTimeout(visionTimer);
    };
  }, []);

  return (
    <div id="intro-root">
      <div id="intro-outer_wave_container">
        <div id="intro-wave_container">
          <div className="custom-shape-divider-bottom-1720816823">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
          </div>
        </div>
      </div>
      <div id="intro-info_container">
        <Wrapper styles={{ height: contentSize }}>
          <div id="intro-image_wrap">
            <img id="intro-image" src={IntroImg} />
          </div>
          <div id="intro-text_container">
            <div id="intro-buisiness_text_top_wrap">
              <p id="intro-buisiness_header_text" className={className.headerText} style={{ display: className.headerText === '' ? '' : 'block' }}>
                Comprehensive Social Media Management, Content Creation, and Marketing Consulting
              </p>
              <p id="intro-buisiness_about_text" className={className.aboutText} style={{ display: className.aboutText === '' ? '' : 'block' }}>
                Salt Media, founded and operated by Caitlyn Van Horn, is driven by her passion for creative business marketing.
                At 25, Caitlyn ventured into corporate America after earning her Master’s
                degree but soon realized it wasn't the right fit. After months of contemplation, she launched Salt Media.
              </p>
              {/* <p id="intro-buisiness_about_text">
                Salt Media is owned and operated by Caitlyn Van Horn,
                a 25 year old with a passion for creative business marketing.
                Caitlyn joined corporate America after graduating with her Masters Degree,
                but quickly felt out of place. After months of debating on what to do, Salt Media was born.
              </p> */}
              <p id="intro-buisiness_vision_text" className={className.visionText} style={{ display: className.visionText === '' ? '' : 'block' }}>
                Our mission is to be a faithful source of creativity and to curate impactful marketing strategies for our clients.
              </p>
            </div>
            <div id="intro-buisiness_text_bottom_wrap">
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  )
};

export default Introduction;