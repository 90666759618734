import React, { useState, useEffect } from 'react';
import { useMutation } from "react-query";
import './contact-form.css';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper';
import colors from '../../../shared/constants/colors';

// shared

const ContactForm = () => {
  const [erroredInput, setErroredInput] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    referral: '',
    message: '',
  });

  const onChange = (e) => setFormState({ ...formState, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formState);

    handleEmptyValidation();
    // resetForm();
  };

  const resetForm = () => {
    setFormState({
      firstName: '',
      lastName: '',
      email: '',
      referral: '',
      message: '',
    });
  };
  
  const handleEmailValidation = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmptyValidation = () => {
    const { firstName, lastName, email, message } = formState;
    const erroredInputCopy = { ...erroredInput };

    if (!firstName) erroredInputCopy.firstName = true;
    if (!lastName) erroredInputCopy.lastName = true;
    if (!email) erroredInputCopy.email = true;
    if (!message) erroredInputCopy.message = true;

    setErroredInput(erroredInputCopy);

    return firstName && lastName && email && message;
  };

  useEffect(() => {
    console.log(formState);
  }, [formState]);
  /*
  how did you hear about us
  - social media
  - referral 
  - google search
  - other
  */

  return (
    <form id="contact-form_form" onSubmit={onSubmit}>
      <Wrapper styles={{ justifyContent: 'space-between' }}>
        <div className="contact-form_name_input_wraps">
          <label className="contact-form_input_labels" htmlFor="firstName" style={{ color: erroredInput.firstName ? `red` : `` }}>First Name <span className="contact-form_sub_labels">(required)</span></label>
          <input
            className="contact-form_inputs"
            name="firstName"
            onChange={onChange}
            type="text"
            value={formState.firstName}
            style={{ borderColor: erroredInput.firstName ? `red` : `` }}
          />
        </div>
        <div className="contact-form_name_input_wraps">
          <label className="contact-form_input_labels" htmlFor="lastName">Last Name <span className="contact-form_sub_labels">(required)</span></label>
          <input
            className="contact-form_inputs"
            name="lastName"
            onChange={onChange}
            type="text"
            value={formState.lastName}
          />
        </div>
      </Wrapper>
      <div className="contact-form_input_wraps">
        <label className="contact-form_input_labels" htmlFor="email">Email <span className="contact-form_sub_labels">(required)</span></label>
        <input
          className="contact-form_inputs"
          name="email"
          onChange={onChange}
          type="email"
          value={formState.email}
        />
      </div>
      <div className="contact-form_input_wraps">
        <label className="contact-form_input_labels" htmlFor="email">How did you hear about us?</label>
        <select
          className="contact-form_inputs"
          name="referral"
          onChange={onChange}
          type="dropdown"
          value={formState.referral}
        > 
          <option value="">Select the best option</option>
          <option value="Social Media">Social Media</option>
          <option value="Referral">Referral</option>
          <option value="Google Search">Google Search</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className="contact-form_input_wraps">
        <label className="contact-form_input_labels" htmlFor="message">Message <span className="contact-form_sub_labels">(required)</span></label>
        <textarea
          className="contact-form_textarea"
          name="message"
          rows="5"
          onChange={onChange}
          value={formState.message}
        />
      </div>
      <Wrapper styles={{ justifyContent: 'flex-start' }}>
        <button id="contact-form_submit_btn" type="submit">Submit</button>
      </Wrapper>
    </form>
  );
};

export default ContactForm;