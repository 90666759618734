import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";

// pages
import Home from './containers/Home';

// components
import Nav from "./components/Layout/Nav/Nav";
import ScrollBar from "./components/Layout/ScrollBar/ScrollBar";

function App() {
  const queryClient = new QueryClient();
  
  return (
    <QueryClientProvider client={queryClient}>
      <ScrollBar />
      <Nav />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="*" element={<NotFound message="The page you're looking for does not exist!" />} /> */}
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;