import React from 'react';
import { FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa";
import './social-media.css';
import './social.css';

// components
import Wrapper from '../../Layout/Wrapper/Wrapper'; 

// shared
import colors from '../../../shared/constants/colors';

const Social = () => {

  return (
    <Wrapper>
      <a className="social-link_wrap" href="https://www.instagram.com/shareyoursalt/" target="_blank" rel="noopener noreferrer">
        <FaInstagram className="social-icon" />
      </a>
      <a className="social-link_wrap" href="https://www.facebook.com/profile.php?id=61558750262652&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
        <FaFacebook className="social-icon" />
      </a>
      <a className="social-link_wrap" href="https://www.tiktok.com/@shareyoursalt?" target="_blank" rel="noopener noreferrer">
        <FaTiktok className="social-icon" />
      </a>
    </Wrapper>
  )
};

export default Social;