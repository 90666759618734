import React, { useRef, useEffect, useState } from 'react';
import './contact-media.css';
import './contact.css';

// components
import Wrapper from '../Layout/Wrapper/Wrapper';
import Social from './Social/Social';
import ContactForm from './ContactForm/ContactForm';

// shared 
import colors from '../../shared/constants/colors';
import { getMiddleOfScreen } from '../../shared/utils/utils';

const Contact = () => {
  const contactRef = useRef(null);
  const [shouldRender, setShouldRender] = useState(false);

  const handleScroll = (top) => {
    const middleOfScreen = getMiddleOfScreen();

    if (middleOfScreen >= top) {
      setShouldRender(true);
    }
  };

  useEffect(() => {
    const rect = contactRef.current.getBoundingClientRect();
    window.addEventListener('scroll', () => handleScroll(rect.top), { passive: true });

    return () => {
      window.removeEventListener('scroll', () => handleScroll(rect.top));
    };
  }, []);

  return (
    <article id="contact-root" ref={contactRef}>
      <section id="contact-left_section">
        <div id="contact-partial_bg">
          <div id="contact-text_wrap" style={{ display: shouldRender ? 'block' : 'none' }} className={shouldRender ? 'contact-animate-image' : ''}>
            <h4 id="contact-title">FILL OUT THE FORM AND GIVE US A FOLLOW IF YOU'RE INTERESTED IN WORKING WITH SALT MEDIA!</h4>
            <Social />
          </div>
        </div>
      </section>
      <section id="contact-right_section">
      <div id="contact-form">
            <ContactForm />
          </div>
      </section>
    </article>
  );
};

export default Contact;