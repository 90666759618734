import React from 'react';
import './footer.css';

// components
import Wrapper from '../Wrapper/Wrapper';

const Footer = () => {

  const renderCCYear = () => {
    const year = new Date().getFullYear();
    if (year === 2024) {
      return year;
    }
    return `2024 - ${year}`;
  };

  return (
    <footer id="footer">
      <Wrapper>
        <p id="footer-text">Copyright &copy; {renderCCYear()} All Rights Reserved By Salt Media, LLC </p>
      </Wrapper>
    </footer>
  );
};

export default Footer;