import React, { useState, useEffect } from 'react';
import './nav.css';
import Logo from '../../../assets/black_sm_logo.png';

const Nav = () => {
  const MOBILE_WIDTH = 768;
  const [isMobileNav, setIsMobileNav] = useState(window.innerWidth <= MOBILE_WIDTH ? true : false);
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const onMobileNavClick = () => {
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= MOBILE_WIDTH) {
        setIsMobileNav(true);
      } else {
        setIsMobileNav(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <nav id="nav">
        <div id="desktopNav" className="navInnerContentWrap" style={{ display: !isMobileNav ? 'flex' : 'none' }}>
          <h4 className="navName">Salt Media</h4>
          {/* <img id="desktopNavLogo" src={Logo} /> */}
          <section className="navLinkWrap">
            {/* <a href="#contact-form" className="navLink js-link"><span className="navSpan">Contact</span></a> */}
            <a href="#contact-form" className="navLink">Contact</a>
          </section>
        </div>

        <div id="mobileNav" style={{ display: isMobileNav ? 'flex' : 'none' }}>
        <h4 className="navName">Salt Media</h4>
          <div id="mobileNavBtn" className={openMobileNav ? 'open' : ''} onClick={onMobileNavClick}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
      <div id="sideNav" className={`menu ${openMobileNav ? 'activeNav' : '' }`} style={{ display: isMobileNav ? 'flex' : 'none' }}>
        <div id="mobileLinkWrap">
          <a href="#contact-form" onClick={onMobileNavClick} className="mobileLink">Contact</a>
          <img alt="Jack Attack" id="mobileJackAttack" src={Logo} />
        </div>
      </div>
    </>
  );
};

export default Nav;