import React, { useEffect, useRef, useState } from 'react';
import AboutImg from '../../assets/about_img.jpg';
import './about.css';
import './about-media.css';

// compoenents 
import Wrapper from '../Layout/Wrapper/Wrapper';

// utils
import colors from '../../shared/constants/colors';
import { getMiddleOfScreen } from '../../shared/utils/utils';

const About = () => {
  const aboutRef = useRef(null);
  const [shouldRender, setShouldRender] = useState(false);
  
  const handleScroll = (top) => {
    const middleOfScreen = getMiddleOfScreen();

    if (middleOfScreen >= top) {
      setShouldRender(true);
    }
  };

  useEffect(() => {
    const rect = aboutRef.current.getBoundingClientRect();
    window.addEventListener('scroll', () => handleScroll(rect.top), { passive: true });

    return () => {
      window.removeEventListener('scroll', () => handleScroll(rect.top));
    };
  }, []);

  return (
    // <div>
    //   <Wrapper styles={{ justifyContent: 'space-evenly' }}>
    // <article id="about-left_wrap">
    //   <h2 id="about-title">MEET CAITLYN VAN HORN</h2>
    //   <p className="about-paragraph">
    //     Hi there! Thanks for visiting my website and having an interest in Salt Media. 
    //     Here’s a little bit about me:
    //   </p>
    //   <p className="about-paragraph" >
    //     As part of Gen Z, I have been involved in social media for as long as I can remember. 
    //     I’ve grown up learning a wide variety of online media platforms and have spent a great 
    //     deal of time studying which strategies work best for each. 
    //   </p>
    //   <p className="about-paragraph" >
    //     I hold a Master of Arts in Mass Communication and Graduate Certificate 
    //     in Advanced Digital and Social Media from Texas Tech University and a 
    //     Bachelor of Business Administration in Accounting from the University of Houston.
    //     Additionally, I have a plethora of online certifications ranging from social media
    //     marketing to SEO to content marketing and more.
    //   </p>
    //   <p className="about-paragraph" >
    //     Creativity has always been a passion of mine. 
    //     Whether it’s in the form of art, photography, writing, graphic design, etc., my heart has always 
    //     felt the most full when doing something that allows me to deep dive into creative expression.
    //      That is exactly what I hope to do through Salt Media!
    //   </p>
    //   <p className="about-paragraph" >
    //     I look forward to working with you and your brand!
    //   </p>
    //   <p className="about-paragraph" >
    //     Stay salty!
    //   </p>
    //   <p className="about-paragraph" >
    //     -Caitlyn
    //   </p>
    // </article>
    // <article id="about-right_wrap">
    //   <img id="about-img" alt="About Image" src={Cait} />
    // </article>
    //   </Wrapper>
    // </div>
    <article id="about-root">
      <section id="about-left_section">
        <div id="about-text_container">
          <h2 id="about-title">MEET CAITLYN VAN HORN</h2>
          <p className="about-paragraph">
            Hi there! Thanks for visiting my website and having an interest in Salt Media.
            Here’s a little bit about me:
          </p>
          <p className="about-paragraph" >
            As part of Gen Z, I have been involved in social media for as long as I can remember.
            I’ve grown up learning a wide variety of online media platforms and have spent a great
            deal of time studying which strategies work best for each.
          </p>
          <p className="about-paragraph" >
            I hold a Master of Arts in Mass Communication and Graduate Certificate
            in Advanced Digital and Social Media from Texas Tech University and a
            Bachelor of Business Administration in Accounting from the University of Houston.
            Additionally, I have a plethora of online certifications ranging from social media
            marketing to SEO to content marketing and more.
          </p>
          <p className="about-paragraph" >
            Creativity has always been a passion of mine.
            Whether it’s in the form of art, photography, writing, graphic design, etc., my heart has always
            felt the most full when doing something that allows me to deep dive into creative expression.
            That is exactly what I hope to do through Salt Media!
          </p>
          <p className="about-paragraph" >
            I look forward to working with you and your brand!
          </p>
          <p className="about-paragraph" >
            Stay salty!
          </p>
          <p className="about-paragraph" >
            -Caitlyn
          </p>
        </div>
      </section>
      <section id="about-right_section" ref={aboutRef} >
        <div id="about-partial_bg">
          <img id="about-img" alt="About Image" style={{ display: shouldRender ? 'block' : 'none' }} className={shouldRender ? 'about-animate-image' : ''} src={AboutImg} />
        </div>
      </section>
    </article>
  );
};

export default About;