import React from 'react';

// components
import Spacer from '../components/Layout/Spacer/Spacer';
import Introduction from '../components/Introduction/Introduction';
import About from '../components/About/About';
import Contact from '../components/Contact/Contact';
import Footer from '../components/Layout/Footer/Footer';

const Home = () => {

  return (
    <>
      <Introduction />
      <Spacer style={{ margin: '2rem 0 6rem 0' }}>
        <About />
      </Spacer>
      <Spacer>
        <Contact />
      </Spacer>
      <Footer />
    </>
  );
};

export default Home;